<template>
  <div class="visibilityRegie-setting">
    <div v-if="initLoading" class="init-loading three-dots-loading">
      Chargement en cours
    </div>
    <div v-else>
      <b-row class="content-regie">
        <b-col class="groupe" col xs="12" sm="6" md="5">
          <h4>Régies</h4>
          <div
            class="block"
            v-if="getvisibiliteRegie && getvisibiliteRegie.length"
          >
            <b-list-group>
              <b-list-group-item
                @click.prevent.stop="handleClickRegie(regie)"
                v-for="(regie, index) in getvisibiliteRegie"
                :key="index"
                :value="index.id"
              >
                <div class="regie">
                  <div class="titre-regie">
                    {{ regie.name }}
                  </div>
                  <div
                    class="icon"
                    v-if="selectedRegie && selectedRegie.id == regie.id"
                  >
                    <font-awesome-icon icon="check" />
                  </div>
                </div>
              </b-list-group-item>
            </b-list-group>
          </div>
          <div v-else class="aucun-regie">Aucun Regie</div>
        </b-col>
        <b-col class="utilisateur-regie" col xs="12" sm="6" md="7">
          <h4>Utilisateurs</h4>
          <div class="select">
            <multiselect
              v-model="users"
              :options="listUserVisibilityComputed"
              @select="handleSelect"
              placeholder="Rechercher l'utilisateur"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="true"
              :preserve-search="true"
              label="full_name"
              track-by="id"
              :preselect-first="false"
              :loading="getvisibiliteRegieUsersLoading"
              :custom-label="nameWithLang"
            >
            </multiselect>
          </div>
          <div
            class="three-dots-loading style"
            v-if="getvisibiliteRegieLoading"
          >
            Chargement en cours
          </div>
          <b-list-group v-if="selectedRegie">
            <b-list-group-item
              v-for="user in selectedRegie.users"
              :key="user.id"
            >
              <div class="user">
                {{ user.first_name }} {{ user.last_name }}
                <strong class="type"
                  >({{ user.type | typeFormat }})<br />{{ user.email }}</strong
                >
              </div>
              <div
                class="button"
                @click="handleDeleteUserClick(user)"
                title="Supprimer"
              >
                X
              </div>
            </b-list-group-item>
          </b-list-group>
          <div
            class="message"
            v-if="
              !getvisibiliteRegieLoading &&
                selectedRegie &&
                selectedRegie.users &&
                selectedRegie.users.length == 0
            "
          >
            Aucun utilisateur
          </div>
        </b-col>
      </b-row>
    </div>
    <!-- Delete utilisteur -->
    <b-modal
      no-close-on-backdrop
      ref="ModalDeleteUsers"
      id="ModalDeleteUsers"
      title="Supprimer un utilisateur"
      :hide-footer="true"
    >
      <p>
        Êtes-vous certain de vouloir supprimer l'utilisateur
        <strong> {{ userToDelete ? userToDelete.full_name : '' }}</strong>
        ?
      </p>
      <div class="message">
        <div v-if="getvisibiliteRegieLoading" class="three-dots-loading">
          Chargement en cours
        </div>
        <div v-if="getvisibiliteRegieError" class="error">
          <ul v-if="Array.isArray(getvisibiliteRegieError)">
            <li v-for="(e, index) in getvisibiliteRegieError" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ getvisibiliteRegieError }}</span>
        </div>
      </div>
      <div class="form-actions">
        <b-button
          @click="hideModal('ModalDeleteUsers')"
          variant="outline-secondary"
        >
          Annuler
        </b-button>
        <b-button @click="supprimerUser" variant="success">
          Valider
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Visiblity',
  data() {
    return {
      selectedRegie: null,
      selectedUser_id: null,
      users: null,
      userToDelete: null,
      initLoading: true
    }
  },
  methods: {
    ...mapActions([
      'fetchListeUsersVoirEquipe',
      'fetchListeTeamsRegie',
      'fetchListeUsersEquipe',
      'makeTeamVisibleUsers',
      'makeTeamInvisibleUsers',
      'resetErrorVisibilityRegie'
    ]),
    hideModal(ref) {
      this.$refs[ref].hide()
      this.resetErrorVisibilityRegie()
    },
    nameWithLang({ full_name, type }) {
      let formatType = ''
      switch (type) {
        case 'user.admin':
          formatType = 'Utilisateur Admin'
          break
        case 'user.agent':
          formatType = 'Agent'
          break
        case 'user.resp.plan':
          formatType = 'Responsable Planning'
          break
        case 'user.supervisor':
          formatType = 'Régie Superviseur'
          break
        case 'user.commercial':
          formatType = 'Régie Commercial'
          break
        case 'user.final':
          formatType = 'Régie Utilisateur Final'
          break
        case 'user.technicien':
          formatType = 'Filiale Poseur'
          break
        case 'user.administration':
          formatType = 'CERTIGAIA Administration'
          break
        case 'user.client':
          formatType = 'Client'
          break
        case 'user.commercial.ite':
          formatType = 'AMO'
          break
        case 'user.sub-super-admin':
          formatType = 'Sous Super Admin'
          break
        case 'user.ingenieur':
          formatType = 'Ingénieur'
          break
        case 'user.visiteur.tech':
          formatType = 'Visiteur Technique'
          break
        case 'user.tech.travaux':
          formatType = 'Technicien Travaux'
          break
        default:
          formatType = ''
          break
      }
      return `${full_name} (${formatType})`
    },
    async handleClickRegie(regie) {
      this.selectedRegie = regie
      this.fetchListeUsersVoirEquipe({
        regie,
        id: regie.id,
        exclude: ['visibilityFilter', 'team', 'visibleTeams', 'userPipe']
      })
      await this.fetchListeUsersEquipe({
        regie,
        id: regie.id,
        exclude: ['visibilityFilter', 'team', 'visibleTeams', 'userPipe']
      })
    },
    handleDeleteUserClick(user) {
      this.userToDelete = user
      this.$refs['ModalDeleteUsers'].show()
    },
    async handleSelect(user) {
      const response = await this.makeTeamVisibleUsers({
        regie: this.selectedRegie,
        usersToAdd: user,
        exclude: [
          'pipeline.cstages',
          'stage.csubstages',
          'visibleToUsers.visibilityFilter',
          'team',
          'visibleTeams',
          'userPipe'
        ]
      })
      if (response) {
        this.users = null
        this.resetErrorVisibilityRegie()
      }
    },
    async supprimerUser() {
      const response = await this.makeTeamInvisibleUsers({
        regie: this.selectedRegie,
        usersToDelete: this.userToDelete,
        exclude: [
          'pipeline.cstages',
          'stage.csubstages',
          'visibleToUsers.visibilityFilter',
          'team',
          'visibleTeams',
          'userPipe'
        ]
      })
      if (response) {
        this.resetErrorVisibilityRegie()
        this.hideModal('ModalDeleteUsers')
      }
    }
  },
  computed: {
    ...mapGetters([
      'getvisibiliteRegie',
      'getVisibilityListeUsers',
      'getvisibiliteRegieError',
      'getvisibiliteRegieLoading',
      'getvisibiliteRegieUsersLoading'
    ]),

    listUserVisibilityComputed: function() {
      return this.getVisibilityListeUsers.filter(user => {
        if (this.selectedRegie && this.selectedRegie.users != null) {
          return (
            this.selectedRegie.users.findIndex(item => item.id === user.id) < 0
          )
        }
      })
    }
  },
  async mounted() {
    await this.fetchListeTeamsRegie()
    this.initLoading = false
  },
  filters: {
    typeFormat: value => {
      switch (value) {
        case 'user.admin':
          return 'Utilisateur Admin'
        case 'user.agent':
          return 'Agent'
        case 'user.resp.plan':
          return 'Responsable Planning'
        case 'user.supervisor':
          return 'Régie Superviseur'
        case 'user.commercial':
          return 'Régie Commercial'
        case 'user.final':
          return 'Régie Utilisateur Final'
        case 'user.technicien':
          return 'Filiale Poseur'
        case 'user.administration':
          return 'CERTIGAIA Administration'
        case 'user.client':
          return 'Client'
        case 'user.commercial.ite':
          return 'AMO'
        case 'user.sub-super-admin':
          return 'Sous Super Admin'
        case 'user.ingenieur':
          return 'Ingénieur'
        case 'user.visiteur.tech':
          return 'Visiteur Technique'
        case 'user.tech.travaux':
          return 'Technicien Travaux'
        default:
          return ''
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.visibilityRegie-setting {
  .content-regie {
    .groupe {
      .block {
        .list-group {
          .list-group-item {
            cursor: pointer;
            .regie {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              .titre-regie {
                display: inline-block;
                font-weight: bold;
                text-transform: capitalize;
              }
              .icon {
                color: #2dabe2;
                margin-right: 12px;
              }
            }
          }
        }
      }
      .aucun-regie {
        color: #2dabe2;
        margin-left: 30px;
        padding: 13px;
      }
    }
    .utilisateur-regie {
      .style {
        margin-top: 15px;
        text-align: center;
      }
      .message {
        text-align: center;
        color: #2dabe2;
      }
      .list-group {
        margin-top: 15px;
        .list-group-item {
          display: flex;
          padding-bottom: 10px;
          padding-top: 10px;
          align-items: center;
          justify-content: space-between;
          .loading {
            margin-right: 176px;
          }
          .user {
            .type {
              color: #2dabe2;
              font-weight: normal;
              font-size: 12px;
              text-transform: capitalize;
            }
          }
          .button {
            cursor: pointer;
          }
        }
      }
    }
  }
  @media screen and (max-width: 400px) {
    .content-regie {
      .utilisateur-regie {
        margin-top: 20px;
        margin-bottom: 15px;
      }
    }
  }
}
</style>
